<template>
  <div class="build-article">
<headers />
     <div class="mb row" style="padding:15px;padding-top: 70px !important;">
      
      <router-link :to="{name:tolink}" class="btn btn-primary btn-back">
      <i class="mdi mdi-arrow-left-bold"></i> {{$language('一覧')}}
    </router-link>
      <div class="col-12">
        <div class="tab-box">
            <ul class="tab-head nav nav-tabs">
              <li class="nav-item" data-id="tc1" @click="tab('tc1')" v-bind:class="[curindex=='tc1' ? 'nav-item active show':'nav-item']">
                <a data-toggle="tab"  class="nav-link" aria-expanded="true">{{$language('物件概要')}}</a>
              </li>
              <li class="nav-item" data-id="tc2" @click="tab('tc2')" v-bind:class="[curindex=='tc2' ? 'nav-item active show':'nav-item']">
                <a data-toggle="tab"  class="nav-link" aria-expanded="true">{{$language('物件詳細')}}
</a>
              </li>  
                  </ul>
                                      
        </div>
        </div>
    </div>
    <div class="row">
              
            <div class="tab-content" style="width:100%;background:#fff">
 
              <div class="embed-responsive-16by9 tab-pane show active tc1"  v-if="curindex=='tc1'">
                  <div class="col-12">
                    
                      <div class="card-box mb-0" id="article_table">
                        <div class="d-flex justify-content-center p-5" v-if="isLoading">
                          <div class="spinner-border m-12" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                        <div v-else-if="!jsonErr">

                          <div v-if="!articleTableEdit">
                            <div class="row d-flex justify-content-end align-items-center mb-1 px-2">
                              <div class="col-10 mb-0 alert alert-primary" v-if="editAlert">{{$language('保存しました')}}</div>
                              <div class="col-2 pr-0">
                                <button type="button" class="btn btn-primary" @click="ToggleArticleTable">{{$language('Edit')}}</button>
                              </div>
                            </div>
                            <build-article-table :viewParams="viewParams" :jsonObj="jsonObj" />
                          </div>

                          <div v-else>
                            <div class="d-flex justify-content-end mb-1">
                              <button type="button" class="btn btn-primary" @click="ToggleArticleTable">Cancel</button>
                            </div>
                            <build-article-table-edit :viewParams="viewParams" :jsonObj="jsonObj" :types="jsonObjTypes" @editData="UpdateData" @successSave="ToggleArticleTable('save')" />
                          </div>

                        </div>
                        <div v-else>
                          <code>
                            {{$language('データ取得に失敗しました')}}
                          </code>
                        </div>
                      </div>
                    
                  </div>
              </div>
              <div class="card-box mb-0"  v-if="curindex=='tc2'">
                  <div class="row">
                      <div class="col-12">
        <google-map v-if="!isLoading" :address="jsonObj.address" :reload="reloadCount" />
        <div class="row" v-else>
          <div class="col-6">
            <div class="page-title-box">
              <h4 class="page-title">{{$language('google_map')}}</h4>
            </div>
            <div class="card-box mb-2">
              <!-- map -->
              <div class="d-flex justify-content-center p-5">
                <div class="spinner-border m-12" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="page-title-box">
              <h4 class="page-title">{{$language('google_street_view')}}</h4>
            </div>
            <div class="card-box mb-2">
              <!-- streetview -->
              <div class="d-flex justify-content-center p-5">
                <div class="spinner-border m-12" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="page-title-box">
              <h4 class="page-title">{{$language('rader_chart')}}</h4>
            </div>
            <div class="card-box mb-2">
              <div class="">
               <apexchart height="500px" width="100%" :options="options" :series="series"></apexchart>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="page-title-box">
              <h4 class="page-title">{{$language('物件の特徴')}}</h4>
            </div>
            <div class="card-box mb-2">
              <!-- rangeslider -->
              <div v-if="!isLoading">
                <div class="mb-3" v-for="(obj, num) in rangeKeys" :key="num">
                  <div v-if="jsonLang[lang][obj]">
                    <h4 class="header-title mb-1">{{ jsonLang[lang][obj] }}</h4>
                    <range-slider :id="obj" :from="extendParams.extended[obj]" @updateRange="UpdateRange"/>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center p-5" v-else>
                <div class="spinner-border m-12" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="page-title-box">
              <h4 class="page-title">{{$language('他の人のコメント')}}<span>({{extendParams.extended_memo_others.length}})</span></h4>
            </div>
            <div class="card-box mb-2">
              <!-- other comments -->
              <div v-if="!isLoading">
                <div class="carousel" v-if="extendParams.extended_memo_others.length > 0">
                  <div class="d-flex other-comments-container">
                    <button type="button" class="btn btn-light other-control other-control-prev" @click.prevent.stop="OtherComments('prev')" v-if="extendParams.extended_memo_others.length > 1">
                      <i class="mdi mdi-chevron-double-left" aria-hidden="true"></i>
                    </button>
                    <div class="other-item card-body" :id="'memo-' + otherCount">
                      <div v-if="extendParams.extended_memo_others[otherCount].memo">{{extendParams.extended_memo_others[otherCount].memo}} </div>
                      <div v-else class="text-secondary">{{$language('コメント未入力')}}</div>
                      <span style="color:#ccc;position:absolute;right:5px;bottom:5px;">{{$language('日付')}}：{{extendParams.extended_memo_others[otherCount].updated_at}}</span>
                    </div>
                    <button type="button" class="btn btn-light other-control other-control-next" @click.prevent.stop="OtherComments('next')" v-if="extendParams.extended_memo_others.length > 1">
                      <i class="mdi mdi-chevron-double-right" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
                <div v-else>
                  <p class="text-muted">{{$language('コメントがありません')}}</p>
                </div>
              </div>
              <div class="d-flex justify-content-center p-5" v-else>
                <div class="spinner-border m-12" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>

            </div>
          </div>
          <div class="col-6">
            <div class="page-title-box">
              <h4 class="page-title">{{$language('コメント')}}</h4>
            </div>
            <div class="card-box mb-2">
              <!-- comment -->
              <div v-if="!isLoading">
                <textarea class="form-control" rows="10" v-model="extendParams.extended.memo"></textarea>
              </div>
              <div class="d-flex justify-content-center p-5" v-else>
                <div class="spinner-border m-12" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 btn-toolbar justify-content-end align-items-center row pr-0 pl-3">
            <div class="col-11 mb-0 alert alert-primary" v-if="saveAlert === 1">{{$language('ご意見有難う御座います。内容を確認してシステム上に反映します。')}}</div>
            <div class="col-11 mb-0 alert alert-danger" v-if="saveAlert === 9">{{$language('保存に失敗しました')}}</div>
            <div class="col-1 pr-0">
              <button type="button" class="btn btn-primary" @click="SaveParams">{{$language('save')}}</button>
            </div>
          </div>
        </div>
      </div>
      </div>      
        </div>
    </div>
  </div>
       
  </div>
</template>

<script>
import axios from 'axios';
import { inject } from 'vue';
import BuildArticleTable from '@/components/BuildArticleTable.vue'
import BuildArticleTableEdit from '@/components/BuildArticleTableEdit.vue'
import GoogleMap from '@/components/GoogleMap.vue'
import RangeSlider from '@/components/RangeSlider.vue'
import router from '@/router'
import Headers from '@/components/Headers.vue'

export default {
  name: 'BuildDetail',
  components: {
    BuildArticleTable,
    BuildArticleTableEdit,
    GoogleMap,
    RangeSlider,
    Headers,
  },
  data() {
    const { currentRoute } = router
    return {
      viewParams: {
        name: 'build1',
        apiUrl: '/v1/properties1/' + currentRoute.value.params.dataId,
        paramKey: 'BuildingID',
      },
      raderChartURL: process.env.VUE_APP_PRP_CHART_URL_BASE + currentRoute.value.params.dataId + '.html',
      jsonObj: [Object],
      jsonObjTypes: [Object],
      isLoading: true,
      jsonErr: false,
      articleTableEdit: false, // true = edit table
      extendParams: {
        extended: {},
        extended_memo_self: {},
        extended_memo_others: [],
      },
      rangeKeys: [],
      jsonLang: {},
      lang: 'ja',
      saveAlert: 0, // 保存結果
      otherCount: 0,
      reloadCount: 0, // map再読み込み用
      editAlert: false,
      options:{},
      series:[],
      curindex:'tc1',
      tolink:localStorage.getItem('homeindex'),
      lan:inject('global').language_data,lan_help:inject('global').language_help_data,
    }
  },
  mounted() {
    this.jsonLang = require('@/assets/languages/ListArticle.json')
    axios
      .get(this.viewParams.apiUrl)
      .then(response => {
          this.jsonObj = response.data.data
          this.jsonObjTypes = response.data.types
          if(response.data.extended) {
            this.extendParams.extended = response.data.extended
          } else {
            this.extendParams.extended = {
              Address:this.jsonObj.address,
              BuildingID: this.jsonObj.BuildingID,
              feature_1: null,
              feature_2: null,
              feature_3: null,
              feature_4: null,
              feature_5: null,
              feature_6: null,
              feature_7: null,
              feature_8: null,
            }
          }
          if(response.data.extended_memo_self) {
            this.extendParams.extended.memo = response.data.extended_memo_self.memo
          } else {
            this.extendParams.extended_memo_self = { memo: null }
          }
          if(response.data.extended_memo_others) {
            this.extendParams.extended_memo_others = response.data.extended_memo_others
          }
          this.RangeKeysFilter()
          this.isLoading = false
      })
      .catch(error => (
          console.log(error),
          this.jsonErr = true,
          this.isLoading = false
        )
      );
  },
  methods: {
    ToggleArticleTable: function(opt) {
      this.articleTableEdit = !this.articleTableEdit
      if(opt === 'save') {
        document.getElementById('article_table').scrollTo({
          top: 0,
        });
        window.scrollTo({
          top: 0,
        });
        this.editAlert = true
      } else {
        this.editAlert = false
      }
    },
    UpdateData: function(data) {
      this.jsonObj = data
      this.reloadCount++
    },
    SaveParams: function() {
      this.saveAlert = 0
      axios
        .put(this.viewParams.apiUrl + '/extended', {
          extended: this.extendParams.extended
        })
        .then(() => {
          this.saveAlert = 1
        })
        .catch(error => {
          this.saveAlert = 9
          console.log(error)
        });
    },
    RangeKeysFilter: function() {
      let Ary = Object.keys(this.extendParams.extended)
      //'feature_'からはじまるキー
      this.rangeKeys = Ary
      this.rangeKeys = Ary.filter( key => key.startsWith('feature_') )
      var cats=[];
      var dt=[];
      for(var d in this.rangeKeys){
        cats.push(this.jsonLang[this.lang][this.rangeKeys[d]]);
        dt.push(this.extendParams.extended[this.rangeKeys[d]]);
      }
      console.log(this.rangeKeys);
       this.series= [{
            name: '物件の特徴',
            data: dt,
          }];
          this.options={
            chart: {
              height: 500,
              type: 'radar',
            },
            
            xaxis: {
              categories: cats
            },
          };
          
    },
    UpdateRange(res) {
      this.extendParams.extended[res.id] = res.data.from
    },
    OtherComments: function(key) {
      if(key === 'next') {
        if(this.extendParams.extended_memo_others[this.otherCount + 1]) {
          this.otherCount++
        } else {
          this.otherCount = 0
        }
      } else {
        if(this.otherCount !== 0) {
          this.otherCount--
        } else {
          this.otherCount = this.extendParams.extended_memo_others.length - 1
        }
      }
    },
    tab:function(tid){
      this.curindex=tid;
    }
  }
}
</script>

<style lang="scss">
.btn-back {
  position: absolute;
  right: 0;
  top: 20px;
  z-index: 255;
}

.build-article .card-box {
  max-height: 1460px;
  overflow-y: auto;
}

.build-article .row > div {
  display: flex;
  flex-direction: column;
}

.build-article .card-box {
  flex: 1;
}

// rader-chart
.build-article .embed-responsive-1by1 {
  max-height: 550px;
}

.build-article .row .btn-toolbar {
  flex-direction: row;
}

.other-comments-container {
  position: relative;
}

.other-item {
  display: block;
  flex: 1;
  height: 230px;
  overflow-y: auto;
  padding: .5rem 1rem;
  word-break: break-all;
}

.other-control {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1.5rem .25rem;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: .15s;
}

.other-control-prev {
  left: -1.5rem;
}

.other-control-next {
  right: -1.5rem;
}
</style>